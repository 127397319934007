import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s Modern Slavery Statement',
  header: 'Modern Slavery Statement',
  noindex: true
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Modern slavery is a heinous crime and a morally reprehensible act that deprives a person's liberty and dignity for another person's gain. It is a real problem for millions of people around the world, including many in developed countries, who are being kept and exploited in various forms of slavery. Every company is at risk of being involved in this crime through its own operations and its supply chain.`}</p>
    <p>{`At Uploadcare, we have a zero-tolerance approach to modern slavery and are fully committed to preventing slavery and human trafficking in our operation and supply chain. We have taken concrete steps to tackle modern slavery, as outlined in our statement. This statement sets out the actions that we have taken to understand all potential modern slavery risks related to our business, and to implement steps to prevent slavery and human trafficking during the financial year 2022.`}</p>
    <h2 {...{
      "id": "our-business-and-supply-chains"
    }}><a parentName="h2" {...{
        "href": "#our-business-and-supply-chains"
      }}>{`Our business and supply chains`}</a></h2>
    <p>{`We are a technology company that brings the best user experience to its customers and their end-users through its innovative content delivery services and breakthrough algorithms. Businesses of every size, from new startups to public companies, use our software to manage and serve visual content to billions of consumers online.`}</p>
    <p>{`We establish a relationship of trust and integrity with all our suppliers, which is built upon mutually beneficial factors. Our supplier selection and on-boarding procedure includes due diligence of the supplier's reputation, respect for the law, compliance with health, safety and environmental standards, and references.`}</p>
    <p>{`We haven't been made aware of any allegations of human trafficking/slavery activities against any of our suppliers, but if we were, then we would act immediately against the supplier and report it to the authorities.`}</p>
    <h2 {...{
      "id": "risk-assessment"
    }}><a parentName="h2" {...{
        "href": "#risk-assessment"
      }}>{`Risk assessment`}</a></h2>
    <p>{`In the past year, we conducted a risk assessment of our supply chain by taking into account:`}</p>
    <ul>
      <li parentName="ul">{`The risk profile of individual countries based on the Global Slavery Index`}</li>
      <li parentName="ul">{`The business services rendered by the suppliers`}</li>
      <li parentName="ul">{`The presence of vulnerable demographic groups`}</li>
      <li parentName="ul">{`A news analysis and the insights of labour and human rights groups`}</li>
    </ul>
    <p>{`This assessment will determine our response and the risk controls that we implement.`}</p>
    <h2 {...{
      "id": "policies"
    }}><a parentName="h2" {...{
        "href": "#policies"
      }}>{`Policies`}</a></h2>
    <p>{`Uploadcare operates the following policies for identifying and preventing slavery and human trafficking in our operations:`}</p>
    <ul>
      <li parentName="ul">{`Whistleblowing Policy — we encourage all employees, customers and suppliers to report any suspicion of slavery or human trafficking without fear of retaliation. We provide a confidential helpline to protect the identity of whistle-blowers.`}</li>
      <li parentName="ul">{`CodeExample of Conduct — our code encourages employees to do the right thing by clearly stating the actions and behaviour expected of them when representing the business. We strive to maintain the highest standards of employee conduct and ethical behaviour when operating abroad and managing our supply chain.`}</li>
    </ul>
    <h2 {...{
      "id": "supplier-due-diligence"
    }}><a parentName="h2" {...{
        "href": "#supplier-due-diligence"
      }}>{`Supplier due diligence`}</a></h2>
    <p>{`Uploadcare conducts due diligence on all new suppliers during on-boarding and on existing suppliers at regular intervals. This includes:`}</p>
    <ul>
      <li parentName="ul">{`Assessing risks in the provision of particular services`}</li>
      <li parentName="ul">{`Auditing the suppliers, and their health and safety standards, labour relations and employee contracts`}</li>
      <li parentName="ul">{`Requiring improvements to substandard employment practices`}</li>
      <li parentName="ul">{`Sanctioning suppliers that fail to improve their performance in line with our requirements`}</li>
    </ul>
    <p>{`We're sure that our suppliers:`}</p>
    <ul>
      <li parentName="ul">{`Don't use any form of forced, compulsory or slave labour`}</li>
      <li parentName="ul">{`Their employees work voluntarily and are entitled to leave work`}</li>
      <li parentName="ul">{`They provide each employee with an employment contract that contains a reasonable notice period for terminating their employment`}</li>
      <li parentName="ul">{`They don't require employees to post a deposit/bond and don't withhold their salaries for any reasons`}</li>
      <li parentName="ul">{`They don't require employees to surrender their passports or work permits as a condition of
employment`}</li>
    </ul>
    <h2 {...{
      "id": "awareness--training"
    }}><a parentName="h2" {...{
        "href": "#awareness--training"
      }}>{`Awareness & Training`}</a></h2>
    <p>{`Uploadcare has raised awareness of modern slavery issues by accepting Whistleblowing Policy and CodeExample of Conduct.`}</p>
    <h2 {...{
      "id": "measuring-how-were-performing"
    }}><a parentName="h2" {...{
        "href": "#measuring-how-were-performing"
      }}>{`Measuring how we're performing`}</a></h2>
    <p>{`Uploadcare has defined a set of key performance indicators and controls to combat modern slavery and human trafficking in our organisation and supply chain. These include:`}</p>
    <ul>
      <li parentName="ul">{`How many employees have accepted CodeExample of Conduct?`}</li>
      <li parentName="ul">{`How many suppliers have rolled out an awareness and training programme that is equivalent to ours?`}</li>
      <li parentName="ul">{`How many reports have been made by our employees that indicate their awareness of and sensitivity to ethical issues?`}</li>
    </ul>
    <p>{`This statement covers 1 April 2020 to 31 March 2021 and has been approved by the board of Uploadcare at the board meeting on 1 April 2021.`}</p>
    <p>{`Igor Debatur, CEO
1 April 2021`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      